import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as LoadableRoutes from "./routes";
import "./App.scss";
import { Toaster } from "react-hot-toast";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const App = () => {
  const {
    Home,
    LandingPage,
    Access,
    About,
    Early,
    Careers,
    Cart,
    Checkout,
    Support,
    Blog,
    BlogDetails,
    Merc,
    SingleMerc,
    NotFound,
  } = LoadableRoutes;

  const history = createBrowserHistory();

  return (
    <main className="main">
      <HashRouter history={history}>
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "green",
                color: "#ffffff",
              },
            },
            error: {
              style: {
                background: "orangered",
                color: "#ffffff",
              },
            },
          }}
        />
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Switch>
                  <Route exact path="/" component={Home} />
                  {/* <Route exact path="/home" component={LandingPage} />
                  <Route exact path="/early-access" component={Early} />
                  <Route path="/get-early-access" component={Access} />
                  <Route exact path="/who-we-are" component={About} />
                  <Route exact path="/careers" component={Careers} />
                  <Route exact path="/support" component={Support} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/blog/:slug" component={BlogDetails} />
                  <Route exact path="/checkout" component={Checkout} />
                  <Route exact path="/cart" component={Cart} />
                  <Route exact path="/merc/:id" component={SingleMerc} />
                  <Route exact path="/merc" component={Merc} /> */}
                  <Route path="*" component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </HashRouter>
    </main>
  );
};

export default App;
