import Loadable from "react-loadable";
import loadingSpinner from "./loading.js";

export const LandingPage = Loadable({
  loader: () => import("../views/LandingPage"),
  loading: loadingSpinner,
});

export const Home = Loadable({
  loader: () => import("../views/Home"),
  loading: loadingSpinner,
});

export const Access = Loadable({
  loader: () => import("../views/Access"),
  loading: loadingSpinner,
});

export const About = Loadable({
  loader: () => import("../views/About"),
  loading: loadingSpinner,
});

export const Early = Loadable({
  loader: () => import("../views/Early"),
  loading: loadingSpinner,
});

export const Careers = Loadable({
  loader: () => import("../views/Careers"),
  loading: loadingSpinner,
});

export const Support = Loadable({
  loader: () => import("../views/Support"),
  loading: loadingSpinner,
});

export const Merc = Loadable({
  loader: () => import("../views/Merc"),
  loading: loadingSpinner,
});

export const SingleMerc = Loadable({
  loader: () => import("../views/Merc/Single"),
  loading: loadingSpinner,
});

export const Blog = Loadable({
  loader: () => import("../views/Blog"),
  loading: loadingSpinner,
});

export const BlogDetails = Loadable({
  loader: () => import("../views/Blog/details"),
  loading: loadingSpinner,
});

export const Checkout = Loadable({
  loader: () => import("../views/Checkout"),
  loading: loadingSpinner,
});

export const Cart = Loadable({
  loader: () => import("../views/Cart"),
  loading: loadingSpinner,
});

export const NotFound = Loadable({
  loader: () => import("../views/NotFound"),
  loading: loadingSpinner,
});
