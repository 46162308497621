import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';



import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";


const API =
  "https://api-us-east-1.graphcms.com/v2/cknowbvb0ton901z39mjhavxz/master";

const client = new ApolloClient({
  link: new HttpLink({ uri: API }),
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);